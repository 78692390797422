import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import FigureUrl1 from '~/assets/shapes-internship/intern-recruitment-1.svg';
import FigureUrl2 from '~/assets/shapes-internship/intern-recruitment-2.svg';
import FigureUrl3 from '~/assets/shapes-internship/intern-recruitment-3.svg';
import FigureUrl4 from '~/assets/shapes-internship/intern-recruitment-4.svg';
import FigureUrl5 from '~/assets/shapes-internship/intern-recruitment-5.svg';
import FigureUrl6 from '~/assets/shapes-internship/intern-recruitment-6.svg';
import Layout from '~/components/Layout';

import SwingInternUrl from './assets/swing-intern.svg';
import RecruitmentForm from './components/RecruitmentForm';
import ThankYouMessage from './components/ThankYouMessage';
import styles from './MastazSignup.module.scss';

const InternRecruitmentComponent: React.FC = () => {
  const [state, setState] = useState({
    isSubmitted: false,
  });

  const setIsSubmitted = () => {
    // setState removes form too fast and cancels form submitting
    setTimeout(() => {
      setState({ isSubmitted: true });
    }, 500);
  };

  const { isSubmitted } = state;

  return (
    <Layout invertNavigation>
      <Helmet>
        <title>Swingdev | SwingMaStaż</title>
      </Helmet>

      <div className={styles.root}>
        <img className={styles.figure1} src={FigureUrl1} alt='figure' />
        <img className={styles.figure2} src={FigureUrl2} alt='figure' />
        <img className={styles.figure3} src={FigureUrl3} alt='figure' />
        <img className={styles.figure4} src={FigureUrl4} alt='figure' />
        <img className={styles.figure5} src={FigureUrl5} alt='figure' />
        <img className={styles.figure6} src={FigureUrl6} alt='figure' />
        <img className={styles.figure7} src={FigureUrl5} alt='figure' />
        <img className={styles.figure8} src={FigureUrl6} alt='figure' />

        <img src={SwingInternUrl} alt='SwingMaStaż' className={styles.image} />

        {!isSubmitted ? (
          <RecruitmentForm onSuccessfulSubmit={setIsSubmitted} />
        ) : (
          <ThankYouMessage />
        )}
      </div>
    </Layout>
  );
};

export default InternRecruitmentComponent;
