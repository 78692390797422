import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { Controller, useForm } from 'react-hook-form';
import cx from 'classnames';

import Button from '~/components/Button';
import Input from '~/components/Input';

import styles from './RecruitmentForm.module.scss';
import {
  MAILCHIMP_INTERNSHIP_SIGNUP_ID,
  MAILCHIMP_INTERNSHIP_SIGNUP_URL,
  MAILCHIMP_USERID,
} from '~/constants';
interface FormData {
  EMAIL: string;
}

type Props = {
  onSuccessfulSubmit: () => void;
};

const RecruitmentForm: React.FC<Props> = ({ onSuccessfulSubmit }) => {
  const { errors, handleSubmit, control } = useForm<FormData>({
    defaultValues: {
      EMAIL: '',
    },
  });

  return (
    <React.Fragment>
      <div className={styles.subtitle}>
        Please, leave us your e-mail address to be notified when the application
        process starts.
      </div>

      <MailchimpSubscribe
        url={`${MAILCHIMP_INTERNSHIP_SIGNUP_URL}?u=${MAILCHIMP_USERID}&id=${MAILCHIMP_INTERNSHIP_SIGNUP_ID}`}
        render={({ subscribe, status, message }) => {
          if (status === 'success') {
            onSuccessfulSubmit();
          }

          return (
            <form onSubmit={handleSubmit(subscribe)} className={styles.form}>
              <div
                className={cx(styles.buttonContainer, {
                  [styles.error]: errors.EMAIL,
                })}
              >
                <Controller
                  name='EMAIL'
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <Input
                      label='E-mail address'
                      type='EMAIL'
                      name='EMAIL'
                      className={styles.emailInput}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      autoComplete='email'
                      status={errors.EMAIL ? 'invalid' : 'valid'}
                      statusMessage={
                        errors.EMAIL ? errors.EMAIL.message : undefined
                      }
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'Email is required.',
                    },
                    minLength: {
                      value: 5,
                      message: 'Minimum length is 5 characters.',
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address.',
                    },
                  }}
                />
                <Button
                  as={{
                    tag: 'button',
                    type: 'submit',
                  }}
                  isLoading={status === 'sending'}
                  className={styles.submitButton}
                >
                  Notify me
                </Button>
              </div>
              {status === 'error' && (
                <p className={styles.formInformation}>{message}</p>
              )}
              <p className={styles.formInformation}>
                By clicking, I agree that Swing Development Sp. z o. o. can
                store and process my personal data from this form for
                recruitment purposes. We&apos;ll only use your email address to
                inform you about our internship program. We won&apos;t send
                spam, newsletters, or anything you wouldn&apos;t want – ever!
              </p>
            </form>
          );
        }}
      />
    </React.Fragment>
  );
};

export default RecruitmentForm;
