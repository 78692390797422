import React from 'react';

import Button from '~/components/Button';
import routes from '~/routes';

import styles from './ThankYouMessage.module.scss';

const ThankYouMessage = () => (
  <React.Fragment>
    <p className={styles.title}>
      Stay tuned - we will send you a remainder when the application process
      starts.
    </p>

    <p className={styles.subtitle}>Thanks, and hopefully, see you at Swing!</p>

    <Button
      className={styles.backButton}
      as={{ tag: 'Link', to: routes.INTERNSHIP }}
    >
      Back to MaStaż page
    </Button>
  </React.Fragment>
);

export default ThankYouMessage;
